import React from 'react';
import ReactDOM from 'react-dom';

import { setNativeReady } from './redux/actions/ui/native';
import configure from './redux/configureStore';
import { persistStore } from './redux/persist';
import { storeType } from './redux/util/types';
import { setLoading, setWaiting } from './redux/actions/ui/loading';

import App from './App';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// Initialize the store
const store: storeType = configure.default({});

// Display the loading screen
store.dispatch(setLoading(true));
store.dispatch(setWaiting(true));

// Rehydrate the store with persisted data
const persistor = persistStore(store);

ReactDOM.render(
  // <React.StrictMode>
  //   <App persistor={persistor} store={store} />
  // </React.StrictMode>,
  <App persistor={persistor} store={store} />,
  document.getElementById('root')
);

// Make it known that CordovaJS has been loaded into context
document.addEventListener('deviceready', () => store.dispatch(setNativeReady(true)), false);

// Register the serviceworker (if it hasn't been registered yet)
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
