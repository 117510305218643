import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

const baseType = 'TIRES';

export const readTires = (vehicle: any) => (dispatch: Dispatch) => {
  return dispatch(
    createAction(baseType, Schemas.TIRE, `/voertuig/${vehicle.id}/banden/info`, 'GET', { _vehicle: vehicle.id })
  );
};

export const readTireChange = (vehicle: any) => (dispatch: Dispatch) => {
  return dispatch(
    createAction('TIRE_CHANGE', Schemas.TIRE_CHANGE, `/voertuig/${vehicle.id}/banden/wisselinfo`, 'GET', {
      _vehicle: vehicle.id,
    })
  );
};
