import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

const baseType = 'REPAIR';

export const readRepair = (vehicle: any) => (dispatch: Dispatch) => {
  return dispatch({
    ...{ payload: vehicle },
    ...createAction(baseType, Schemas.REPAIR, `/voertuig/${vehicle.id}/onderhoud/plannen`, 'GET', {
      _vehicle: vehicle.id,
    }),
  });
};
