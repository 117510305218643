import { createReducer } from '@reduxjs/toolkit';

import { saveContent } from '../../actions/content/content';

const content = createReducer(
  {},
  {
    [saveContent.toString()]: (state: { [key: string]: { [key: string]: any } }, action) => {
      state[action.payload.type] = {
        ...state[action.payload.type],
        [action.payload.key]: action.payload.value,
      };
    },
  }
);

export default content;
