import { createReducer } from '@reduxjs/toolkit';

import { STURING_READ_SUCCESS, STURING_READ_FAILURE } from '../../actionTypes/entities/sturingen';

const sturingen = createReducer(
  {},
  {
    [STURING_READ_SUCCESS]: (state: any, action: any) => {
      state.current = Object.assign({}, action.response.entities.sturing, state.current);
    },
    [STURING_READ_FAILURE]: (state: any, action: any) => {
      if (!state.current) {
        state.current = {};
      }
      state.current[action.payload.id] = {};
    },
  }
);

export default sturingen;
