import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import type { Theme, WithStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

type Styles = WithStyles<'root'>;

const LoadingView = ({ classes }: Styles) => (
  <div className={classes.root + ' root loading-view'}>
    <CircularProgress color="secondary" />
  </div>
);

// eslint-disable-next-line
const styles = (theme: Theme) => ({
  root: {},
});

export default withStyles(styles, { withTheme: true })(LoadingView) as React.FC;
