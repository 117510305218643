import { createReducer } from '@reduxjs/toolkit';

const insurance = createReducer(
  {},
  {
    // Default CRUD
  }
);

export default insurance;
