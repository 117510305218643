import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { callApiMiddleware } from './middleware/api';
import { persistReducer } from './persist';
import reducer from './reducers/reducers';

const configure = (preloadedState: any) =>
  configureStore({
    reducer: persistReducer(reducer),
    preloadedState,
    middleware: [
      callApiMiddleware,
      thunk,
      // ...getDefaultMiddleware()
    ],
  });

export default configure;
