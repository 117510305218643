import { createReducer } from '@reduxjs/toolkit';

import { setAppState } from '../../actions/app/app';

const app = createReducer(
  {
    refresh: null,
    refreshContent: null,
  },
  {
    [setAppState.toString()]: (state: { [key: string]: any }, action) => {
      Object.keys(action.payload).forEach((key) => (state[key] = action.payload[key]));
    },
  }
);

export default app;
