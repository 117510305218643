import { createReducer } from '@reduxjs/toolkit';

import { setAuth, setInitialized } from '../../actions/auth/auth';

const auth = createReducer(
  {
    auth: null,
    initialized: false,
    switchedUser: false,
  },
  {
    // Auth
    [setAuth.toString()]: (state, action) => {
      state.auth = action.payload.auth;
    },

    // Initialize
    [setInitialized.toString()]: (state) => {
      state.initialized = true;
    },
  }
);

export default auth;
