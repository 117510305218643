import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

const baseType = 'CONTRACT';

export const readContract = (vehicle: any) => (dispatch: Dispatch) => {
  return dispatch(
    createAction(baseType, Schemas.CONTRACT, `/voertuig/${vehicle.id}/contract`, 'GET', { _vehicle: vehicle.id })
  );
};
