export const BASE_URL_APP = '';

// export const URL_BACKEND_API = 'https://api.bapp.broekhuis.online/index.php';
export const URL_BACKEND_API =
  process.env.NODE_ENV === 'production' && window.location.hostname === 'berijderapp.broekhuislease.nl'
    ? 'https://berijderapp.broekhuislease.nl/api'
    : 'https://berijderapp.esb-acc.broekhuis.online';
export const SNACKBAR_DURATION = 6000;

export const REGEX_EMAIL_ADDRESS = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export const REGEX_LICENSE_PLATE = /([a-zA-Z]|\d)+-([a-zA-Z]|\d)+-([a-zA-Z]|\d)+/;
export const REGEX_PHONE_NUMBER =
  /^(?:0|(?:\+|00) ?31 ?)(?:(?:[1-9] ?(?:[0-9] ?){8})|(?:6 ?-? ?[1-9] ?(?:[0-9] ?){7})|(?:[1,2,3,4,5,7,8,9]\d ?-? ?[1-9] ?(?:[0-9] ?){6})|(?:[1,2,3,4,5,7,8,9]\d{2} ?-? ?[1-9] ?(?:[0-9] ?){5}))$/;
