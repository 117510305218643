import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';

const baseType = 'DOCS';

export const getDoc =
  (url: string, format = 'jpg') =>
  (dispatch: Dispatch) => {
    return dispatch({
      ...{ payload: { doc: url } },
      ...createAction(
        baseType,
        null,
        `/pdf/convert`,
        'POST',
        {},
        {
          pdfUrl: url,
          format,
        },
        undefined,
        'GET'
      ),
    });
  };

export const loadPage =
  (doc: string[], page = 0, save = false) =>
  (dispatch: Dispatch) => {
    return loadFile(doc[page], save)(dispatch);
  };

export const loadFile =
  (url: string, save = false) =>
  (dispatch: Dispatch) => {
    return dispatch({
      ...{ payload: { url } },
      ...createAction('DOCS_PAGE', null, url, 'GET', {}, undefined, {
        save,
      }),
    });
  };
