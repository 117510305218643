import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';

const url = '/app/watchdog';
const baseType = 'WATCHDOG';

export interface WatchDogArgs {
  logType: string;
  message: string;
  severity: number;
  referer: string;
}

export const watchdog = (data: WatchDogArgs) => (dispatch: Dispatch) => {
  return dispatch({
    ...{
      payload: data,
    },
    ...createAction(baseType, null, url, 'POST', undefined, data, { showMsg: false }),
  });
};
