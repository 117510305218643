import { createReducer } from '@reduxjs/toolkit';

const tires = createReducer(
  {},
  {
    // Default CRUD
  }
);

export const tireChanges = createReducer({}, {});

export default tires;
