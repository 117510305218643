import { createReducer } from '@reduxjs/toolkit';

import { setFullscreen } from '../../actions/ui/fullscreen';
import { setLoading, setWaiting } from '../../actions/ui/loading';
import { setNativeReady } from '../../actions/ui/native';
import { createNotification, deleteNotification } from '../../actions/ui/notification';
import { setRefresh } from '../../actions/ui/refresh';
import { setTitle } from '../../actions/ui/title';
import { VEHICLE_STATUS_READ_SUCCESS } from '../../actionTypes/entities/vehicles';
import { resetDeletedVehicles } from '../../actions/ui/vehicles';

const ui = createReducer(
  {
    deletedVehicles: [],
    fullscreen: false,
    loading: false,
    nativeReady: false,
    notifications: [] as {
      id: any;
      text: string;
      action?: () => void;
      actionText?: string;
    }[],
    refresh: new Date(1970, 0, 1),
    title: null,
    waiting: false,
  },
  {
    // Fullscreen
    [setFullscreen.toString()]: (state, action) => {
      state.fullscreen = action.payload.fullscreen;
    },

    // Loading
    [setLoading.toString()]: (state, action) => {
      state.loading = action.payload.loading;
    },

    // Native
    [setNativeReady.toString()]: (state, action) => {
      state.nativeReady = action.payload.nativeReady;
    },

    // Notifications
    [createNotification.toString()]: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },

    [deleteNotification.toString()]: (state, action) => {
      state.notifications = [...state.notifications].filter((n) => n.id !== action.payload.id);
    },

    // Page title
    [setTitle.toString()]: (state, action) => {
      state.title = action.payload.title;
    },

    // Refresh
    [setRefresh.toString()]: (state, action) => {
      state.refresh = action.payload.date;
    },

    // Waiting
    [setWaiting.toString()]: (state, action) => {
      state.waiting = action.payload.waiting;
    },

    [VEHICLE_STATUS_READ_SUCCESS]: (state, action) => {
      state.deletedVehicles = action.kentekens;
    },

    [resetDeletedVehicles.toString()]: (state) => {
      state.deletedVehicles = [];
    },
  }
);

export default ui;
