import { CALL_API } from '../middleware/api';

import transformPayload from '../util/transformPayload';

const toTypes = (baseType: string, method: string, customCrud?: string) => {
  baseType = baseType.toUpperCase();

  let crud;
  switch (method) {
    case 'GET':
      crud = 'READ';
      break;
    case 'POST':
      crud = 'CREATE';
      break;
    case 'PUT':
      crud = 'UPDATE';
      break;
    case 'DELETE':
      crud = 'DELETE';
      break;
    default:
      crud = 'READ';
  }

  if (customCrud) crud = customCrud;

  return [`${baseType}_${crud}_REQUEST`, `${baseType}_${crud}_SUCCESS`, `${baseType}_${crud}_FAILURE`];
};

export interface ActionConfig {
  save: boolean;
  showMsg: boolean;
}

const defaultConfig: ActionConfig = {
  save: false,
  showMsg: true,
};

export const createAction = (
  baseType: string,
  schema: any,
  url: string,
  method = 'GET',
  insertResponse = {},
  body: any = undefined,
  config: Partial<ActionConfig> = {},
  customCrud?: string
) => {
  const options = {
    method,
    body: body ? JSON.stringify(transformPayload(body)) : undefined,
  };

  return {
    type: 'callAPI',
    [CALL_API]: {
      types: toTypes(baseType, method, customCrud),
      endpoint: url,
      schema,
      options,
      config: Object.assign({}, defaultConfig, config),
      insertResponse,
    },
  };
};

const createCrudAction = (
  baseType: string,
  schema: any,
  url: string,
  method = 'GET',
  body: any = null,
  history = null,
  showMsg = true,
  insertResponse = {}
) => {
  const options = {
    method,
    body: body ? JSON.stringify(transformPayload(body)) : undefined,
  };

  return {
    type: 'callAPI',
    [CALL_API]: {
      history,
      types: toTypes(baseType, method),
      endpoint: url,
      schema,
      options,
      showMsg,
      insertResponse,
    },
  };
};

export default createCrudAction;
