import { createReducer } from '@reduxjs/toolkit';

import { DOCS_GET_SUCCESS } from '../../actionTypes/docs/docs';

type State = { [key: string]: string[] };

const docs = createReducer(
  {},
  {
    [DOCS_GET_SUCCESS]: (state, action) => {
      (state as State)[action.payload.doc] = action.response;
    },
  }
);

export default docs;
