const transformPayload = (payload: { [key: string]: any }) => {
  const res: { [key: string]: any } = {};

  Object.keys(payload).forEach((key) => {
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    res[newKey] = payload[key];
  });

  return res;
};

export const transformResponse: (response: any) => any = (response: any) => {
  const res: { [key: string]: any } = {};

  Object.keys(response).forEach((key) => {
    const newKey = key.replace(/_+([^\W_])|_+/g, (match: string) => match.replace('_', '').toUpperCase());
    res[newKey] = response[key];
  });

  return res;
};

export default transformPayload;
