import localForage from 'localforage';

import {
  FILE_LOAD_REQUEST,
  FILE_LOAD_SUCCESS,
  FILE_LOAD_FAILURE,
  FILE_SAVE_REQUEST,
  FILE_SAVE_SUCCESS,
  FILE_SAVE_FAILURE,
  FILE_STORE,
} from '../../actionTypes/files/files';

import { Dispatch } from '../../util/types';

const FILES = localForage.createInstance({
  driver: [localForage.INDEXEDDB, localForage.WEBSQL],
  name: 'broekhuisLeaseBerijderApp',
  storeName: 'files',
  description: 'Broekhuis Lease Berijder App',
});

const makeBlob = (value: any) => URL.createObjectURL(new Blob([value]));

export const loadFile = (url: string) => (dispatch: Dispatch) => {
  dispatch({ type: FILE_LOAD_REQUEST, payload: { url } });

  return FILES.getItem(url)
    .then((file) => {
      if (file === null)
        return dispatch({
          type: FILE_LOAD_FAILURE,
          payload: { url },
        });

      return dispatch({
        type: FILE_LOAD_SUCCESS,
        payload: { url },
        response: { url, file: makeBlob(file), content: file },
      });
    })
    .catch((err) => {
      return dispatch({
        type: FILE_LOAD_FAILURE,
        error: err,
        payload: { url },
      });
    });
};

export const saveFile = (url: string, value: any) => (dispatch: Dispatch) => {
  dispatch({ type: FILE_SAVE_REQUEST, payload: { url, value } });
  return FILES.setItem(url, value)
    .then(() => {
      return dispatch({
        type: FILE_SAVE_SUCCESS,
        payload: { url, value: makeBlob(value) },
      });
    })
    .catch((err) => {
      return dispatch({
        type: FILE_SAVE_FAILURE,
        error: err,
        payload: { url, value },
      });
    });
};

export const storeFile = (url: string, value: any) => (dispatch: Dispatch) => {
  return dispatch({
    type: FILE_STORE,
    payload: { url, value: makeBlob(value) },
  });
};
