import { persistStore as pStore, persistReducer as pRed, createMigrate } from 'redux-persist';
import type { PersistConfig } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createCompressor from 'redux-persist-transform-compress';
import localForage from 'localforage';

// Configure the storage driver
const storage = localForage.createInstance({
  driver: [localForage.INDEXEDDB, localForage.WEBSQL],
  name: 'broekhuisLeaseBerijderApp',
  storeName: 'redux',
  description: 'Broekhuis Lease Berijder App',
});

// Configure tranformations on the redux state tree
const compressor = createCompressor();

const transforms = [
  // Production only
  ...(process.env.NODE_ENV === 'production' ? [compressor] : []),
];

// Migrations
const migrations = {
  3: (state: any) => {
    return {
      auth: {
        auth: state.auth.auth,
        initialized: false,
      },
      entities: {
        contract: {},
        incidents: {},
        insurance: {},
        repair: {},
        tires: {},
        tireChanges: {},
        users: {},
        vehicles: {},
      },
    };
  },
};

// Configure redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['content', 'files', 'ui'],
  transforms,
  stateReconciler: autoMergeLevel2,
  serialize: true,

  // Migrations
  version: 3,
  migrate: createMigrate(migrations as any, { debug: false }),
};

export const persistReducer = (reducer: any, options?: Partial<PersistConfig<unknown, any, any>>) =>
  pRed(Object.assign({}, persistConfig, options), reducer);

export const persistStore = (store: any, callback?: () => void) => pStore(store, undefined, callback);
