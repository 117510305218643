import createCrudAction, { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

const url = '/voertuigen';
const baseType = 'VEHICLES';

export const readVehicles = () => (dispatch: Dispatch) => {
  return dispatch(createCrudAction(baseType, Schemas.VEHICLE_RESPONSE, `${url}`));
};

export const readVehicleStatus = (vehicles: number[]) => (dispatch: Dispatch) => {
  return dispatch(
    createAction(
      'VEHICLE_STATUS',
      null,
      '/voertuigen/status',
      'POST',
      undefined,
      {
        ids: vehicles,
      },
      undefined,
      'READ'
    )
  );
};
