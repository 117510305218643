import { createReducer } from '@reduxjs/toolkit';

const contract = createReducer(
  {},
  {
    // Default CRUD
  }
);

export default contract;
