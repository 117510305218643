import './scss/styles.scss';

import React, { Suspense } from 'react';
import { compose } from 'redux';
import { connect, Provider } from 'react-redux';

import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { loadAll } from './redux/actions/util/loadAll';
import { setLoading, setWaiting } from './redux/actions/ui/loading';
import { storeType } from './redux/util/types';
import type { Persistor } from 'redux-persist';
import LoadingView from './components/views/LoadingView';

interface Props {
  persistor: Persistor;
  store: storeType;
}

interface State {
  removedSplash: boolean;
}

interface ReduxProps {
  auth: string | null;
  initialized: boolean;
  nativeReady: boolean;
  refresh: string | Date | null;
  user?: any;

  loadAPI: (images: boolean, vehicles: object) => void;
  stopWaiting: () => void;
  stopLoading: () => void;
}

const Routes = React.lazy(() => import('./routes'));

class App extends React.Component<Props & ReduxProps, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      removedSplash: false,
    };

    this.loadAPI = this.loadAPI.bind(this);
  }

  render() {
    return (
      <StylesProvider injectFirst>
        <Provider store={this.props.store}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingView />}>
              <Routes />
            </Suspense>
          </ThemeProvider>
        </Provider>
      </StylesProvider>
    );
  }

  componentDidMount() {
    this.hideSplash();

    this.props.persistor.subscribe(() => {
      // App is now ready to use, so stop displaying the loading screen
      this.props.stopWaiting();

      // Refresh the data in the data store
      this.loadAPI();
    });
  }

  componentDidUpdate() {
    this.hideSplash();
  }

  hideSplash() {
    if (this.props.nativeReady && !this.state.removedSplash) {
      (navigator as any).splashscreen.hide();
      this.setState({ removedSplash: true });
    }
  }

  loadAPI() {
    if (this.props.auth === null) {
      return;
    }

    this.props.loadAPI(!this.props.initialized, (this.props.store.getState() as any).entities.vehicles);
  }
}

const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  mixins: { toolbar: { minHeight: 64 } },
  shape: { borderRadius: 3 },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 800,
    fontWeightBold: 800,
    h6: { marginTop: '1rem' },
  },

  palette: {
    common: { black: '#0a0a0a', white: '#fefefe' },
    type: 'light',
    primary: {
      light: '#60d9ff',
      main: '#00a7d6',
      dark: '#0673b0',
      contrastText: '#fefefe',
    },
    secondary: {
      light: '#ffff50',
      main: '#ffcc00',
      dark: '#c79c00',
      contrastText: '#0a0a0a',
    },
  },
});

const mapStateToProps = (state: any) => ({
  auth: state.auth.auth,
  initialized: state.auth.initialized,
  nativeReady: state.ui.nativeReady,
  refresh: state.app.refresh,
  user: state.entities.users.current,
});

const mapDispatchToProps = (dispatch: (_: any) => void) => ({
  loadAPI: (images: boolean, vehicles: object) => dispatch(loadAll(images, vehicles)(dispatch as any)),
  stopWaiting: () => dispatch(setWaiting(false)),
  stopLoading: () => dispatch(setLoading(false)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(App) as React.FC<Props>;
