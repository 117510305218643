import { createReducer } from '@reduxjs/toolkit';

import {
  USER_READ_SUCCESS,
  // USERS_DELETE_SUCCESS,
} from '../../actionTypes/entities/users';

const users = createReducer(
  {},
  {
    // Default CRUD
    // [USERS_DELETE_SUCCESS]: (state: any, action: any) => { delete state[action.payload.id] },

    // === Other ===
    // Save the logged in user to state.entities.users.current for ease of access
    [USER_READ_SUCCESS]: (state: any, action: any) => {
      state.current = action.response.entities.user[action.response.result];
    },
  }
);

export default users;
