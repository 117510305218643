// CRUD

export const VEHICLES_CREATE_REQUEST = 'VEHICLES_CREATE_REQUEST';
export const VEHICLES_CREATE_SUCCESS = 'VEHICLES_CREATE_SUCCESS';
export const VEHICLES_CREATE_FAILURE = 'VEHICLES_CREATE_FAILURE';

export const VEHICLES_UPDATE_REQUEST = 'VEHICLES_UPDATE_REQUEST';
export const VEHICLES_UPDATE_SUCCESS = 'VEHICLES_UPDATE_SUCCESS';
export const VEHICLES_UPDATE_FAILURE = 'VEHICLES_UPDATE_FAILURE';

export const VEHICLES_DELETE_REQUEST = 'VEHICLES_DELETE_REQUEST';
export const VEHICLES_DELETE_SUCCESS = 'VEHICLES_DELETE_SUCCESS';
export const VEHICLES_DELETE_FAILURE = 'VEHICLES_DELETE_FAILURE';

export const VEHICLES_READ_REQUEST = 'VEHICLES_READ_REQUEST';
export const VEHICLES_READ_SUCCESS = 'VEHICLES_READ_SUCCESS';
export const VEHICLES_READ_FAILURE = 'VEHICLES_READ_FAILURE';

// Other

export const VEHICLE_READ_REQUEST = 'VEHICLE_READ_REQUEST';
export const VEHICLE_READ_SUCCESS = 'VEHICLE_READ_SUCCESS';
export const VEHICLE_READ_FAILURE = 'VEHICLE_READ_FAILURE';

export const VEHICLE_STATUS_READ_REQUEST = 'VEHICLE_STATUS_READ_REQUEST';
export const VEHICLE_STATUS_READ_SUCCESS = 'VEHICLE_STATUS_READ_SUCCESS';
export const VEHICLE_STATUS_READ_FAILURE = 'VEHICLE_STATUS_READ_FAILURE';
