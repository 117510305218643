// CRUD

// export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
// export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
// export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

// Other

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const USER_READ_REQUEST = 'USER_READ_REQUEST';
export const USER_READ_SUCCESS = 'USER_READ_SUCCESS';
export const USER_READ_FAILURE = 'USER_READ_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const USER_SWITCH_REQUEST = 'USER_SWITCH_REQUEST';
export const USER_SWITCH_SUCCESS = 'USER_SWITCH_SUCCESS';
export const USER_SWITCH_FAILURE = 'USER_SWITCH_FAILURE';
