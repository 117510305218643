import { normalize, schema } from 'normalizr';

import { URL_BACKEND_API } from '../../const';
import { Dispatch } from '../util/types';
import { transformResponse } from '../util/transformPayload';
import type { Middleware } from '@reduxjs/toolkit';

import { createNotification } from '../actions/ui/notification';
import { invalidateAuth } from '../actions/auth/auth';
import { saveFile, storeFile } from '../actions/files/files';
import { ActionConfig } from '../util/createCrudAction';

const mergeOptions: (args?: RequestInit, auth?: string | null) => RequestInit = (args?, auth?) => ({
  ...(args || {}),
  ...{
    headers: {
      'Content-Type': 'application/json',
      ...(args && args.headers ? args.headers : {}),
      ...(auth ? { 'auth-token': auth } : {}),
    },
  },
});

const callApi: (
  dispatch: Dispatch,
  url: string,
  config: ActionConfig,
  schema?: schema.Entity,
  options?: RequestInit,
  insertResponse?: any
) => Promise<object> = (dispatch, endpoint, config, schema, options, insertResponse = {}) => {
  const url =
    endpoint.indexOf('https://') === -1 && endpoint.indexOf('http://') === -1
      ? `${URL_BACKEND_API}${endpoint}`
      : endpoint;

  return fetch(url, options).then(
    (response) => {
      const type = (response.headers.get('Content-Type') as string).split(';')[0];

      // Handle non-ok response-status
      if (!response.ok) {
        switch (response.status) {
          case 401:
          case 403:
            // Cancel auth invalidation if login is incorrect
            if (!options || !options.headers || !(options.headers as any)['auth-token']) break;

            // Otherwise invalidate the user's session
            dispatch(invalidateAuth());

            // If the API did not return a message, then display a custom one
            if (response.statusText === 'No Content') {
              dispatch(createNotification('Uw authenticatie is verlopen'));
            }
            break;
        }

        switch (type) {
          case 'application/json':
            return response.json().then((json) => {
              if (json.message) dispatch(createNotification(json.message));
              return Promise.reject(json);
            });
          default:
            return response.text().then((text) => Promise.reject(text));
        }
      }

      // We don't have to do any processing if there is no content
      if (response.statusText === 'No Content') return {};

      switch (type) {
        // Handle regular JSON responses from the API
        case 'application/json':
          // Allow the save config to imply other handling of the call
          if (config.save)
            return response.arrayBuffer().then((buffer) => {
              saveFile(url, buffer)(dispatch);
              return JSON.parse(new TextDecoder('utf-8').decode(buffer));
            });

          // Otherwise handle it regularly
          return response.json().then((json) => {
            // Transform the API's snake case to camelcase
            json = transformResponse(json);

            // Insert data into the response from the API
            json = Object.assign({}, json, insertResponse);

            // Display any messages from the back-end to the user
            if (json.message && config.showMsg) dispatch(createNotification(json.message));

            // Parse the data and pass it on
            if (schema) json = normalize(json, schema);
            else if (process.env.NODE_ENV !== 'production')
              console.warn(`No schema specified for endpoint: ${endpoint}`);

            return Object.assign({}, json);
          });

        // Handle JPEG images served from the API
        case 'image/jpeg':
          // Read the result
          return response.arrayBuffer().then((buffer) => {
            // Save the file to the file store
            if (config.save) saveFile(url, buffer)(dispatch);
            else storeFile(url, buffer)(dispatch);

            // Pass the key to the file's loction in the file store
            return { url };
          });
      }

      return {};
    },
    (error) => {
      console.error(error);
      // eslint-disable-next-line
      return Promise.reject({
        name: error.name,
        message: error.message,
        code: error.code,
      });
    }
  );
};

export const CALL_API = 'CALL_API';

export const callApiMiddleware: Middleware = (api) => (next) => (action) => {
  // Catch any undefined actions and cancel processing
  if (!action) return;

  // If there is no CALL_API block in the action, then just ignore it and pass the action on to the next middleware
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    if (action.then !== undefined) return;
    return next(action);
  }

  let { endpoint } = callAPI;
  const { schema, types, options = {}, config = { save: false, showMsg: true }, insertResponse = {} } = callAPI;

  // Check whether the API request is correct
  if (typeof endpoint === 'function') endpoint = endpoint(api.getState());
  if (typeof endpoint !== 'string') throw new Error('Specify a string endpoint URL');

  if (!Array.isArray(types) || types.length !== 3) throw new Error('Expected an array of three action types');
  if (!types.every((type) => typeof type === 'string')) throw new Error('Expected action types to be strings');

  // Declare a small action generator
  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  // Parse the types and announce the start of the API request
  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return callApi(next, endpoint, config, schema, mergeOptions(options, api.getState().auth.auth), insertResponse).then(
    (response) =>
      next(
        actionWith({
          response,
          payload: action.payload,
          type: successType,
        })
      ),
    (error) =>
      next(
        actionWith({
          type: failureType,
          error,
        })
      )
  );
};
