import { createReducer } from '@reduxjs/toolkit';

import { FILE_LOAD_SUCCESS, FILE_SAVE_SUCCESS, FILE_STORE } from '../../actionTypes/files/files';

type State = { [key: string]: any };

const files = createReducer(
  {},
  {
    [FILE_LOAD_SUCCESS]: (state, action) => {
      (state as State)[action.response.url] = action.response.file;
    },
    [FILE_SAVE_SUCCESS]: (state, action) => {
      (state as State)[action.payload.url] = action.payload.value;
    },
    [FILE_STORE]: (state, action) => {
      (state as State)[action.payload.url] = action.payload.value;
    },
  }
);

export default files;
