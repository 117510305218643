import { combineReducers } from 'redux';

import { invalidateAuth } from '../actions/auth/auth';

import app from './app/app';
import auth from './auth/auth';
import content from './content/content';
import docs from './docs/docs';
import entities from './entities/entities';
import files from './files/files';
import ui from './ui/ui';
import { VEHICLE_STATUS_READ_SUCCESS } from '../actionTypes/entities/vehicles';
import { USER_SWITCH_SUCCESS } from '../actionTypes/entities/users';

const reducer = combineReducers({
  app,
  auth,
  content,
  docs,
  entities,
  files,
  ui,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === invalidateAuth.toString()) {
    console.warn('AUTHENTICATION INVALIDATED');
    return reducer(undefined, action);
  }
  // eslint-disable-next-line
  else if (action.type == USER_SWITCH_SUCCESS) {
    const initialState = reducer(undefined, action);
    initialState.auth = Object.assign({}, state.auth, { switchedUser: true });
    return initialState;
  } else if (action.type === VEHICLE_STATUS_READ_SUCCESS) {
    action.kentekens = Object.keys(action.response)
      .filter((k) => action.response[k] === 'deleted')
      .map((k) => state.entities.vehicles[k].kenteken);
  }
  return reducer(state, action);
};

export default rootReducer;
