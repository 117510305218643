import { combineReducers } from 'redux';
import { VEHICLE_STATUS_READ_SUCCESS } from '../../actionTypes/entities/vehicles';

import contract from './contract';
import incidents from './incidents';
import insurance from './insurance';
import repair from './repair';
import tires, { tireChanges } from './tires';
import users from './users';
import vehicles from './vehicles';
import sturingen from './sturingen';

const entities = combineReducers({
  contract,
  incidents,
  insurance,
  repair,
  tires,
  tireChanges,
  users,
  vehicles,
  sturingen,
});

// Process entities identified by Normalizr
const reduceEntities = (state: any = {}, action: any) => {
  if (action.response && action.response.entities) {
    return {
      contract: {
        ...state.contract,
        ...action.response.entities.contract,
      },
      incidents: {
        ...state.incidents,
        ...action.response.entities.incidents,
      },
      insurance: {
        ...state.insurance,
        ...action.response.entities.insurance,
      },
      repair: {
        ...state.repair,
        ...action.response.entities.repair,
      },
      tires: {
        ...state.tires,
        ...action.response.entities.tire,
      },
      tireChanges: {
        ...state.tireChanges,
        ...action.response.entities.tireChange,
      },
      users: {
        ...state.users,
        ...action.response.entities.user,
      },
      vehicles: {
        ...state.vehicles,
        ...action.response.entities.vehicle,
      },
      sturingen: {
        ...state.sturingen,
        ...action.response.entities.sturingen,
      },
    };
  } else return state;
};

const reducer = (state: any = {}, action: any) => {
  if (action.type === VEHICLE_STATUS_READ_SUCCESS) {
    Object.keys(action.response).forEach((vehicle) => {
      const status = action.response[vehicle];
      if (status === 'deleted') {
        console.log(`Voertuig #${vehicle} ("${state.vehicles[vehicle].kenteken}") verwijderd.`);

        delete state.contract[vehicle];
        delete state.incidents[vehicle];
        delete state.insurance[vehicle];
        delete state.repair[vehicle];
        delete state.tireChanges[vehicle];
        delete state.tires[vehicle];
        delete state.vehicles[vehicle];
        delete state.sturingen[vehicle];
      }
    });
  }

  // First process entities identified by Normalizr, then process the remaining actions
  return entities(reduceEntities(state, action), action);
};

export default reducer;
