import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_READ_REQUEST,
  USER_READ_SUCCESS,
  USER_READ_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_SWITCH_REQUEST,
  USER_SWITCH_SUCCESS,
  USER_SWITCH_FAILURE,
} from '../../actionTypes/entities/users';

import { CALL_API } from '../../middleware/api';
// import createCrudAction from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

const url = '/berijder';
// const baseType = 'USERS';

export const readUser = () => (dispatch: Dispatch) => {
  return dispatch({
    type: 'readUser',
    [CALL_API]: {
      types: [USER_READ_REQUEST, USER_READ_SUCCESS, USER_READ_FAILURE],
      endpoint: `${url}`,
      options: { method: 'GET' },
      schema: Schemas.USER,
    },
  });
};

export const register = (email: string, type?: string) => (dispatch: Dispatch) => {
  return dispatch({
    type: 'registerUser',
    payload: {
      email,
    },
    [CALL_API]: {
      types: [USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_REGISTER_FAILURE],
      endpoint: type ? `${url}/register/${email}?type=${type}` : `${url}/register/${email}`,
      options: { method: 'POST' },
    },
  });
};

export const login = (email: string, pin: string | number) => (dispatch: Dispatch) => {
  return dispatch({
    type: 'loginUser',
    payload: {
      email,
      pin,
    },
    [CALL_API]: {
      types: [USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE],
      endpoint: `${url}/login/${pin}/${email}`,
      options: { method: 'GET' },
      config: { save: false, showMsg: false },
    },
  });
};

export const switchUser = (email: string) => (dispatch: Dispatch) => {
  return dispatch({
    type: 'switchUser',
    payload: { email },
    [CALL_API]: {
      types: [USER_SWITCH_REQUEST, USER_SWITCH_SUCCESS, USER_SWITCH_FAILURE],
      endpoint: `${url}/switch/${email}`,
      options: { method: 'GET' },
      config: { save: false, showMsg: false },
    },
  });
};

export const logout = () => (dispatch: Dispatch) => {
  return dispatch({
    type: 'logoutUser',
    [CALL_API]: {
      types: [USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE],
      endpoint: `${url}/unregister`,
      options: { method: 'GET' },
    },
  });
};
