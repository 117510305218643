import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';

// eslint-disable-next-line
const baseType = 'STURING';

export const readSturingen = (vehicle: any) => (dispatch: Dispatch) => {
  return dispatch({
    ...{ payload: vehicle },
    ...createAction('STURING', Schemas.STURING, `/voertuig/${vehicle.id}/sturingen`, 'GET', { _vehicle: vehicle.id }),
  });
};
