import { createReducer } from '@reduxjs/toolkit';

// import { VEHICLE_STATUS_READ_SUCCESS } from "../../actionTypes/entities/vehicles";

const vehicles = createReducer(
  {},
  {
    // [VEHICLE_STATUS_READ_SUCCESS]: (state: any, action: any) => {
    //   Object.keys(action.response).forEach((vehicle) => {
    //     const status = action.response[vehicle];
    //     if (status === 'deleted') {
    //       console.log(`Voertuig #${vehicle} ("${state[vehicle].kenteken}") verwijderd.`);
    //       delete state[vehicle];
    //     }
    //   });
    // }
    // [VEHICLES_READ_SUCCESS]: (state: any, action: any) => {
    //   // // TODO: Improve this logic whenever we decide to go back to viewing multiple vehicles
    //   // if (action.response.result.totalResults !== 1)
    //   //   throw new Error("Expected one vehicle returned from API");
    //   // let vehicle = state[action.response.result.entry[0]];
    //   // state["current"] = vehicle;
    // }
  }
);

export default vehicles;
