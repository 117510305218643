import { schema } from 'normalizr';
import { transformResponse } from './util/transformPayload';

const contract = new schema.Entity('contract', {}, { idAttribute: '_vehicle' });

const incidents = new schema.Entity('incidents', {}, { idAttribute: '_vehicle' });

const insurance = new schema.Entity('insurance', {}, { idAttribute: '_vehicle' });

const repair = new schema.Entity('repair', {}, { idAttribute: '_vehicle' });
const sturing = new schema.Entity('sturing', {}, { idAttribute: '_vehicle' });

const tire = new schema.Entity('tire', {}, { idAttribute: '_vehicle' });
const tireChange = new schema.Entity('tireChange', {}, { idAttribute: '_vehicle' });

const vehicle = new schema.Entity(
  'vehicle',
  {},
  {
    idAttribute: 'id',
    processStrategy: transformResponse,
  }
);
const vehicleArray = new schema.Array(vehicle);
const vehicleResponse = new schema.Object({ entry: vehicleArray });

const user = new schema.Entity('user', {}, { idAttribute: 'berijder_nummer' });

export const Schemas = {
  CONTRACT: contract,

  INCIDENTS: incidents,

  INSURANCE: insurance,

  REPAIR: repair,
  STURING: sturing,

  TIRE: tire,
  TIRE_CHANGE: tireChange,

  VEHICLE: vehicle,
  VEHICLE_ARRAY: vehicleArray,
  VEHICLE_RESPONSE: vehicleResponse,

  USER: user,
};

export default Schemas;
