import { createAction } from '@reduxjs/toolkit';
import { Dispatch } from '../../util/types';

import * as app from '../app/app';
import * as docs from '../docs/docs';
import * as files from '../files/files';

const promise: (_: any) => Promise<any> = (dispatch) => {
  return dispatch as unknown as Promise<any>;
};

export const loadFAQ =
  (force = false) =>
  (dispatch: Dispatch) => {
    const base = window.location.origin;

    const download = () => {
      // Update FAQ refresh date
      dispatch(app.setAppState({ refreshContent: new Date() }));

      return promise(docs.loadFile(`${base}/faq/index.json`, true)(dispatch)).then((res: any) => {
        dispatch(saveContent('faq', 'index', res.response));
        return Promise.all(
          res.response.map((cat: any) =>
            Promise.all(
              cat.items.map((item: string) =>
                promise(docs.loadFile(`${base}/faq/${item}.json`, true)(dispatch)).then((file: any) => {
                  dispatch(saveContent('faq', item, file.response));
                  return file.response;
                })
              )
            )
          )
        );
      });
    };

    // If force download, then just do it
    if (force) return download();

    // Otherwise first try to load from cache
    return files
      .loadFile(`${base}/faq/index.json`)(dispatch)
      .then(((res: any) => {
        if (res.type.endsWith('_FAILURE')) return download();

        const content: any[] = JSON.parse(new TextDecoder('utf-8').decode((res as any).response.content));

        dispatch(saveContent('faq', 'index', content));

        return Promise.all(
          content.map((cat) =>
            Promise.all(
              cat.items.map((item: any) =>
                files
                  .loadFile(`${base}/faq/${item}.json`)(dispatch)
                  .then((file) => {
                    const f = JSON.parse(new TextDecoder('utf-8').decode((file as any).response.content));
                    dispatch(saveContent('faq', item, f));

                    return true;
                  })
              )
            )
          )
        );
      }) as (res: any) => any);
  };

export const saveContent = createAction('content/save', (type: string, key: string, value: any) => ({
  payload: {
    type,
    key,
    value,
  },
}));
